import type { RootState, RootThunkAction } from "~redux/store";
import type { Action, ActionCreator } from "@reduxjs/toolkit";
import type {
  BrightButtonId,
  ButtonPath,
  CursorButtonId,
  EramButtonId,
  FontButtonId,
  ToggleButtonId,
} from "types/eramButton";
import {
  bcgButtonList,
  brightButtons,
  cpdlcBrightButtonList,
  cursorButtonList,
  disabledToggleButtons,
  fontButtonList,
  fontButtons,
  mapButtonList,
  toggleButtonList,
} from "types/eramButton";
import type { EramState } from "~redux/slices/eramStateSlice";
import {
  altitudeLimitsSelector,
  nxAltDisplayItemSelector,
  removeAllMacros,
  setAbWidth,
  setBrightness,
  setCursorProps,
  setDrawCircleRadius,
  setDrawColorBright,
  setDrawFontSize,
  setFdbLdr,
  setFontSize,
  setHistory,
  setNexradAltLabelIndex,
  setNexradLevel,
  setVectorLength,
  toggleButton,
  toggleMenuButtonPathValue,
} from "~redux/slices/eramStateSlice";
import type { EramFontSize } from "@poscon/shared-frontend";
import {
  connectionSelector,
  initialMouseSpeed,
  sectorIdSelector,
  setMouseSpeed,
  errorTone,
} from "@poscon/shared-frontend";
import { eramHubConnection } from "~/eramHubConnection";
import {
  checkListSelector,
  cmdMenuItemSelector,
  setConfirmClearAllMacros,
  toggleDelTearOffState,
} from "../slices/eramTempStateSlice";

const setFdbLdrThunk = (value: number): RootThunkAction => {
  return (dispatch) => {
    if (value >= 0 && value <= 3) {
      eramHubConnection.emit("setFdbLdr", value as EramState["fdbLdr"]);
      dispatch(setFdbLdr(value as EramState["fdbLdr"]));
    }
  };
};

const deleteAllMacros = (): RootThunkAction => {
  return (dispatch) => {
    dispatch(removeAllMacros());
    dispatch(setConfirmClearAllMacros(false));
  };
};

const nxLvlTextMap = ["OFF", "123", "23", "3"];
type DeltaButtonActionParam = {
  minValue: number;
  maxValue: number;
  valueSelector: (state: RootState) => number;
  action: (value: number) => Action | RootThunkAction;
};
const deltaButtonActionParamMap: Partial<Record<EramButtonId, DeltaButtonActionParam>> = {
  VECTOR: {
    minValue: 0,
    maxValue: 4,
    valueSelector: (state) => state.eram.vectorLength,
    action: setVectorLength,
  },
  DRAW_CIRCLE_SIZE: {
    minValue: 5,
    maxValue: 50,
    valueSelector: (state) => state.eram.drawCircleRadius,
    action: (value) => setDrawCircleRadius(value as EramState["drawCircleRadius"]),
  },
  DRAW_FONT_SIZE: {
    minValue: 1,
    maxValue: 5,
    valueSelector: (state) => state.eram.drawFontSize,
    action: (value) => setDrawFontSize(value as EramState["drawFontSize"]),
  },
  DRAW_COLOR: {
    minValue: 0,
    maxValue: 100,
    valueSelector: (state) => state.eram.drawColorBright,
    action: (value) => setDrawColorBright(value as EramState["drawCircleRadius"]),
  },
  // target history length range is between 0 and 5, TI 6110.100 - section 3.15.1
  HISTORY: {
    minValue: 0,
    maxValue: 5,
    valueSelector: (state) => state.eram.history,
    action: setHistory,
  },
  // full datablock leader line length can have values between 0 and 3, TI 6110.100 - section 2.8.2
  FDB_LDR: {
    minValue: 0,
    maxValue: 3,
    valueSelector: (state) => state.eram.fdbLdr,
    action: setFdbLdrThunk,
  },
  // AB width can have values between 1 and 10, TI 6110.100 - section 3.3
  AB_WIDTH: {
    minValue: 1,
    maxValue: 10,
    valueSelector: (state) => state.eram.abWidth,
    action: setAbWidth,
  },
  NX_ALT: {
    minValue: 0,
    maxValue: 3,
    valueSelector: (state) => state.eram.nexradAltLabelIndex,
    action: (value) => setNexradAltLabelIndex(value),
  },
  // Nexrad level can have values between 0 and 3, TI 6110.100 - section 2.6.4
  NX_LVL: {
    minValue: 0,
    maxValue: 3,
    valueSelector: (state) => state.eram.nexradLevel,
    action: (value) => setNexradLevel(value as EramState["nexradLevel"]),
  },
  ...Object.fromEntries(
    brightButtons.map((buttonId) => {
      let minValue = 0;
      let maxValue = 100;
      switch (buttonId) {
        case "LINE_4_BRIGHT":
          minValue = -20;
          maxValue = 0;
          break;
        case "SLDB_BRIGHT":
        case "DWELL_BRIGHT":
        case "PORTAL_BRIGHT":
          maxValue = 20;
          break;
        case "BCKGRD_BRIGHT":
          maxValue = 60;
          break;
      }
      if ((cpdlcBrightButtonList.flat() as BrightButtonId[]).includes(buttonId)) {
        minValue = 40;
      }
      return [
        buttonId,
        {
          minValue,
          maxValue,
          valueSelector: (state) => state.eram.brightness[buttonId],
          action: (value) => setBrightness({ buttonId, value }),
        },
      ];
    }),
  ),
  ...Object.fromEntries(
    fontButtons.map((buttonId) => {
      let minValue = 1;
      let maxValue = 5;
      switch (buttonId) {
        case "LINE_4_FONT":
          minValue = -2;
          maxValue = 0;
          break;
        case "TOOLBAR_FONT":
          maxValue = 2;
          break;
        case "OUTAGE_FONT":
          maxValue = 3;
          break;
      }

      return [
        buttonId,
        {
          minValue,
          maxValue,
          valueSelector: (state) => state.eram.font[buttonId],
          action: (value) => setFontSize({ buttonId, value: value as EramFontSize }),
        },
      ];
    }),
  ),
  CURSOR_SPEED: {
    minValue: 1,
    maxValue: 3,
    valueSelector: (state) => state.eram.cursor.CURSOR_SPEED,
    action: (value) => setCursorProps({ buttonId: "CURSOR_SPEED", value }),
  },
  CURSOR_SIZE: {
    minValue: 1,
    maxValue: 5,
    valueSelector: (state) => state.eram.cursor.CURSOR_SIZE,
    action: (value) => setCursorProps({ buttonId: "CURSOR_SIZE", value }),
  },
  CURSOR_VOLUME: {
    minValue: 0,
    maxValue: 5,
    valueSelector: (state) => state.eram.cursor.CURSOR_VOLUME,
    action: (value) => setCursorProps({ buttonId: "CURSOR_VOLUME", value }),
  },
};
const deltaButtonAction = (
  buttonId: EramButtonId,
  delta: number,
  onError?: (errorType: string) => void,
): RootThunkAction => {
  return (dispatch, getState) => {
    const params = deltaButtonActionParamMap[buttonId];
    if (params) {
      const { minValue, maxValue, valueSelector, action } = params;
      const value = valueSelector(getState());
      const newValue = value + delta;
      if (newValue < minValue || newValue > maxValue) {
        const errorType = newValue > maxValue ? "MAX_VALUE" : "MIN_VALUE";
        onError?.(errorType);
        return;
      }
      if (buttonId === "CURSOR_VOLUME") {
        if (errorTone.isPlaying) {
          return;
        }
        errorTone.volume = newValue / 5;
        void errorTone.tryPlay();
      }
      if (buttonId === "CURSOR_SPEED") {
        setMouseSpeed(initialMouseSpeed + (newValue - 2) * 5);
      }
      dispatch(action(newValue));
    }
  };
};
type ButtonMapValue = {
  text?: (state: RootState) => string;
  value?: (state: RootState) => any;
  action?: ActionCreator<any>;
  selected?: (state: RootState, path?: ButtonPath) => boolean;
  disabled?: (state: RootState) => boolean;
};
export const eramButtonActionMap: Partial<Record<EramButtonId, ButtonMapValue>> = {
  AB_WIDTH: {
    text: (state) => `WIDTH\n${state.eram.abWidth}`,
    value: (state) => state.eram.abWidth,
    action: deltaButtonAction,
  },
  OUTAGE: {
    text: (state) => `OUTAGE\n${sectorIdSelector(state)?.split(".").at(-1) ?? "NA"}`,
  },
  ATC_TOOLS_WX: { text: () => "WX" },
  CLR_ALL_MACROS: {
    selected: (state) => state.eramTemp.confirmClearAllMacros,
    action: deleteAllMacros,
  },
  DELETE_TEAROFF: {
    action: toggleDelTearOffState,
    selected: (state) => state.eramTemp.delTearOffCommandActive,
  },
  DRAW_CIRCLE_SIZE: {
    text: (state) => `\` SIZE\n${state.eram.drawCircleRadius}`,
    value: (state) => state.eram.drawCircleRadius,
    action: deltaButtonAction,
  },
  DRAW_COLOR: {
    text: (state) => `COLOR\n${state.eram.drawColorBright}`,
    value: (state) => state.eram.drawColorBright,
    action: deltaButtonAction,
  },
  DRAW_DEL: {
    text: () => "DEL",
  },
  DRAW_DEL_ALL: {
    text: () => "DEL\nALL",
  },
  DRAW_FONT: {
    text: () => "FONT",
  },
  DRAW_FONT_SIZE: {
    text: (state) => `A FONT\n${state.eram.drawFontSize}`,
    value: (state) => state.eram.drawFontSize,
    action: deltaButtonAction,
  },
  DRAW_TEXT: {
    text: () => "A",
  },
  FDB_LDR: {
    text: (state) => `FDB LDR\n${state.eram.fdbLdr}`,
    value: (state) => state.eram.fdbLdr,
    action: deltaButtonAction,
  },
  HISTORY: {
    text: (state) => `HISTORY\n${state.eram.history}`,
    value: (state) => state.eram.history,
    action: deltaButtonAction,
  },
  MACRO_CREATE: {
    text: (state) =>
      `MSG ${
        Object.keys(state.eram.macroButtonMap).length < 99
          ? Object.keys(state.eram.macroButtonMap).length + 1
          : "MAX"
      }`,
  },
  MAP: {
    text: (state) =>
      state.eramTemp.geomapConfig
        ? `${state.eramTemp.geomapConfig.labelLine1}\n${state.eramTemp.geomapConfig.labelLine2 ?? ""}`
        : "",
  },
  NX_ALT: {
    text: (state) => {
      const displayItem = nxAltDisplayItemSelector(state);
      return `${displayItem.labelLine1}\n${displayItem.labelLine2}`;
    },
    value: (state) => state.eram.nexradAltLabelIndex,
    action: deltaButtonAction,
  },
  NX_LVL: {
    text: (state) => `NX LVL\n${nxLvlTextMap[state.eram.nexradLevel]}`,
    value: (state) => state.eram.nexradLevel,
    action: deltaButtonAction,
  },
  VECTOR: {
    text: (state) => `VECTOR ${state.eram.vectorLength === 0 ? 0 : 2 ** (state.eram.vectorLength - 1)}`,
    value: (state) => state.eram.vectorLength,
    action: deltaButtonAction,
  },
  ...(Object.fromEntries([
    ...brightButtons
      .flat()
      .concat(cpdlcBrightButtonList.flat())
      .map((button) => {
        const val = {
          value: (state: RootState) => state.eram.brightness[button],
          text: (state: RootState) => `${button.replace("_BRIGHT", "")}\n${state.eram.brightness[button]}`,
          action: deltaButtonAction,
        };
        switch (button) {
          case "LINE_4_BRIGHT":
            val.text = (state: RootState) =>
              state.eram.brightness[button] === 0 ? "LINE 4\n=" : `LINE 4\n${state.eram.brightness[button]}`;
            break;
          case "DWELL_BRIGHT":
            val.text = (state: RootState) => `DWELL\n+${state.eram.brightness[button]}`;
            break;
          case "SLDB_BRIGHT":
            val.text = (state: RootState) => `SLDB\n+${state.eram.brightness[button]}`;
            break;
          case "PORTAL_BRIGHT":
            val.text = (state: RootState) =>
              state.eram.brightness[button] === 0 ? "PORTAL\n=" : `PORTAL\n+${state.eram.brightness[button]}`;
            break;
          case "NDA_CDA_BRIGHT":
            val.text = (state: RootState) => `NDA/CDA\n${state.eram.brightness[button]}`;
            break;
          case "TOC_IC_BRIGHT":
            val.text = (state: RootState) => `TOC/IC\n${state.eram.brightness[button]}`;
            break;
          default:
            break;
        }
        return [button, val];
      }),
    ...bcgButtonList.flat().map((button, i) => [
      button,
      {
        value: (state: RootState) => state.eram.brightness[button],
        text: (state: RootState) => {
          const bcgButton = state.eramTemp.bcg.buttons.find((b) => b.menuPosition === i + 1);
          return bcgButton ? `${bcgButton.label}\n${state.eram.brightness[button]}` : "";
        },
        action: deltaButtonAction,
      },
    ]),
  ]) as Record<BrightButtonId, ButtonMapValue>),
  ...(Object.fromEntries(
    toggleButtonList.map((button) => {
      const val: ButtonMapValue = {
        selected: (state, path) =>
          path ? state.eram.selectedMenuButtonPaths.includes(path) : state.eram.toggleButtonState[button],
        action: (path?: string) => (path ? toggleMenuButtonPathValue(path) : toggleButton(button)),
        disabled: () => disabledToggleButtons.includes(button),
      };
      const mapIndex = (mapButtonList.flat() as ToggleButtonId[]).indexOf(button);
      switch (button) {
        case "AB_OPEN":
          val.text = (state) => `AB\n${state.eram.toggleButtonState[button] ? "OPEN" : "CLOSED"}`;
          break;
        case "CODE_VIEW":
          val.text = () => "CODE";
          break;
        case "CRR_FIX":
          val.text = () => "CRR\nFIX";
          break;
        case "CRR_RDB":
          val.text = () => "CRR\nRDB";
          break;
        case "STA_RDB":
          val.text = () => "STA\nRDB";
          break;
        case "NON_ADSB":
          val.text = () => "NON\nADS-B";
          break;
        case "NON_MODE_C":
          val.text = () => "NON\nMODE-C";
          break;
        case "PREFSET":
          val.text = (state) => {
            const connection = connectionSelector(state);
            const initials =
              connection?.displayName
                ?.split(/\s+/)
                .map((s) => s[0] ?? "")
                .join("")
                .toUpperCase() ?? "";
            return `PREFSET\n${initials}`;
          };
          break;
        case "ALT_LIM":
          val.text = (state) => {
            const limits = altitudeLimitsSelector(state).limits;
            const str = limits
              ? `${limits.min.toString().padStart(3, "0")}B${limits.max.toString().padStart(3, "0")}`
              : "XXXXXX";
            return `ALT LIM\n${str}`;
          };
          break;
        default:
          if (mapIndex !== -1) {
            val.text = (state) => {
              const button = state.eramTemp.mapFilterMenu.buttons.find(
                (b) => b.menuPosition === mapIndex + 1,
              );
              return button ? `${button.labelLine1}\n${button.labelLine2 ?? ""}` : "";
            };
          } else if (button.includes("_RAISED")) {
            val.text = (state) =>
              `${button.replace("_RAISED", "")}\n${!state.eram.toggleButtonState[button] ? "RAISE" : "LOWER"}`;
          } else if (button.startsWith("CHKLST_")) {
            const position = Number.parseInt(button.replace("CHKLST_", ""), 10);
            val.text = (state) => {
              const checklist = checkListSelector(state, position);
              return checklist
                ? `${checklist.chkListButtonLabelLine1}\n${checklist.chkListButtonLabelLine2}`
                : "";
            };
            val.disabled = (state) => checkListSelector(state, position) === null;
          } else if (button.startsWith("CMD_MENU_")) {
            const position = Number.parseInt(button.replace("CMD_MENU_", ""), 10);
            val.text = (state) => {
              const menu = cmdMenuItemSelector(state, position);
              return menu ? `${menu.catLabelLine1}\n${menu.catLabelLine2 ?? ""}` : "";
            };
            val.disabled = (state) => cmdMenuItemSelector(state, position) === null;
          }
          break;
      }
      return [button, val];
    }),
  ) as Record<ToggleButtonId, ButtonMapValue>),
  ...(Object.fromEntries(
    fontButtonList.flat().map((button) => {
      const res = {
        value: (state: RootState) => state.eram.font[button],
        text: (state: RootState) => `${button.replace("_FONT", "")}\n${state.eram.font[button]}`,
        action: deltaButtonAction,
      };
      if (button === "LINE_4_FONT") {
        res.text = (state: RootState) =>
          state.eram.font[button] === 0 ? "LINE 4\n=" : `LINE 4\n${state.eram.font[button]}`;
      }

      return [button, res];
    }),
  ) as Record<FontButtonId, ButtonMapValue>),
  ...(Object.fromEntries(
    [...cursorButtonList].flat().map((button) => [
      button,
      {
        value: (state: RootState) => state.eram.cursor[button],
        text: (state: RootState) => `${button.replace("CURSOR_", "")}\n${state.eram.cursor[button]}`,
        action: deltaButtonAction,
      },
    ]),
  ) as Record<CursorButtonId, ButtonMapValue>),
};
