import { MessageComposeArea } from "components/MessageComposeArea";
import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  macroButtonMapSelector,
  setToggleButtonValue,
  tearOffPositionMapSelector,
  toggleButtonStateSelector,
} from "~redux/slices/eramStateSlice";
import { unsafeEntries } from "@poscon/shared-types";
import { MessageResponseArea } from "components/MessageResponseArea";
import { Time } from "components/utils/Time";
import { WxReport } from "components/WxReport";
import { AltimSet } from "components/AltimSet";
import { CfrView } from "components/CfrView";
import { AutoHoInhib } from "components/AutoHoInhib";
import { BeaconCodeList } from "components/BeaconCodeList";
import { ConflictAlert } from "components/ConflictAlert";
import { CpdlcAdv } from "components/CpdlcAdv";
import { CpdlcMsgOut } from "components/CpdlcMsgOut";
import { ContRangeReadout } from "components/ContRangeReadout";
import { DeptList } from "components/DeptList";
import { FlightEvent } from "components/FlightEvent";
import { GroupSuppression } from "components/GroupSuppression";
import { HoldList } from "components/HoldList";
import { InboundList } from "components/InboundList";
import { MeterRefPointList } from "components/MeterRefPointList";
import { SaaFilter } from "components/SaaFilter";
import { Status } from "components/Status";
import { TBE, TBH } from "~/eramConstants";
import { useEventListener } from "usehooks-ts";
import { CpdlcHistory } from "components/CpdlcHistory";
import { CpdlcTocSet } from "components/CpdlcTocSet";
import { SettingsMenu } from "components/menus/SettingsMenu";
import { Checklist } from "components/Checklist";
import { RangeMenu } from "components/RangeMenu";
import { WeatherTearOffItems } from "components/weatherTearOffItems";
import { EramTearOffButton } from "components/buttons/EramTearOffButton";
import { DrawContextProvider } from "contexts/drawContext";
import { TearOffContextProvider } from "contexts/tearOffContext";
import { ButtonContextProvider } from "contexts/buttonContext";
import { SituationDisplayContextProvider } from "contexts/sdContext";
import { WeatherTearOffContextProvider } from "contexts/weatherTearOffContext";
import {
  advCreationTemplateView,
  advDeleteView,
  advFreetextTemplateView,
  advUplinkTemplateView,
  connectionSelector,
  eligibilityPromptSelector,
  errorTone,
  InopX,
  InputContextProvider,
  LockedUiBorder,
  selectedAdvViewSelector,
  selectedHeldTocSelector,
  useAutoSignin,
  useReplayContext,
} from "@poscon/shared-frontend";
import { CursorContextProvider } from "contexts/cursorContext";
import type { Graphics } from "pixi.js";
import { SituationDisplay } from "components/SituationDisplay";
import { DragContextProvider } from "contexts/dragContext";
import { BrightContextProvider } from "contexts/brightnessContext";
import { layerZIndexMap } from "~/layerZIndexMap";
import { LogicalPosition } from "@tauri-apps/api/window";
import { EligibilityOverridePrompt } from "components/prompts/EligibilityOverridePrompt";
import { checkListsButtonList, isPriorityButton, priorityButtonList } from "types/eramButton";
import { useMoveCursorToElement } from "hooks/useMoveCursorToElement";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { CpdlcAdvUplinkTemplate } from "components/CpdlcAdvUplinkTemplate";
import { CpdlcAdvCreationTemplate } from "components/CpdlcAdvCreationTemplate";
import { CpdlcAdvDeletePrompt } from "components/CpdlcAdvDeletePrompt";
import { CpdlcAdvFreetextTemplate } from "components/CpdlcAdvFreetextTemplate";
import { CpdlcTocHeldPrompt } from "./prompts/CpdlcTocHeldPrompt";
import { CCommonContextProvider } from "~/contexts/commonContext";
import { initializeConnection } from "~/eramHubConnection";
import { aselSelector } from "~/redux/slices/eramTempStateSlice";
import { AselComponent } from "./AselComponent";
import { VoiceRecordingPromptWrapper } from "./prompts/VoiceRecordingPrompt";

const buttonViewMap = {
  SETTINGS_MENU: SettingsMenu,
  ALTIM_SET: AltimSet,
  AUTO_HO_INHIB: AutoHoInhib,
  CFR: CfrView,
  CODE_VIEW: BeaconCodeList,
  CONFLCT_ALERT: ConflictAlert,
  CRR: ContRangeReadout,
  DEPT_LIST: DeptList,
  FLIGHT_EVENT: FlightEvent,
  GROUP_SUP: GroupSuppression,
  HOLD_LIST: HoldList,
  INBND_LIST: InboundList,
  MRP_LIST: MeterRefPointList,
  SAA_FILTER: SaaFilter,
  STATUS: Status,
  WX_REPORT: WxReport,
  RANGE_MENU: RangeMenu,
  ...Object.fromEntries(
    checkListsButtonList.flat().map((btnId) => [btnId, () => <Checklist view={btnId} />] as const),
  ),
} as const;

const cpdlcViewMap = {
  CPDLC_ADV: CpdlcAdv,
  CPDLC_HIST: CpdlcHistory,
  CPDLC_MSGOUT: CpdlcMsgOut,
  CPDLC_TOC_SET: CpdlcTocSet,
} as const;

const cpdlcAdvViewMap = {
  [advCreationTemplateView]: CpdlcAdvCreationTemplate,
  [advUplinkTemplateView]: CpdlcAdvUplinkTemplate,
  [advDeleteView]: CpdlcAdvDeletePrompt,
  [advFreetextTemplateView]: CpdlcAdvFreetextTemplate,
};

export const Eram = () => {
  useAutoSignin(initializeConnection);
  const dispatch = useRootDispatch();
  const connection = useRootSelector(connectionSelector);
  const macroButtonMap = useRootSelector(macroButtonMapSelector);
  const tearOffPositionMap = useRootSelector(tearOffPositionMapSelector);
  const toggleButtonState = useRootSelector(toggleButtonStateSelector);
  const asel = useRootSelector(aselSelector);
  const eligibilityPrompt = useRootSelector(eligibilityPromptSelector);
  const tbhCounterRef = useRef(0);
  const sdRef = useRef<Graphics>(null);
  const selectedAdvView = useRootSelector(selectedAdvViewSelector);
  const selectedHeldToc = useRootSelector(selectedHeldTocSelector);
  const moveCursorToElement = useMoveCursorToElement();
  const replayState = useReplayContext();

  useEventListener(
    "mousedown",
    (event) => {
      if (window.__TAURI__ && !replayState.replayMode && connection?.isActive) {
        if (event.buttons === TBH) {
          moveCursorToElement("BUTTON_VECTOR");
          tbhCounterRef.current = 1;
        } else if ([3, 6].includes(event.buttons)) {
          event.stopPropagation();
          if (event.button === TBE) {
            switch (tbhCounterRef.current % 3) {
              case 0:
                moveCursorToElement("BUTTON_VECTOR");
                break;
              case 1:
                dispatch(
                  setToggleButtonValue({
                    buttonId: "RANGE_MENU",
                    newValue: true,
                  }),
                );
                setTimeout(() => moveCursorToElement("rangeMenuZoomButton"), 20);
                break;
              case 2:
                void WebviewWindow.getCurrent().setCursorPosition(
                  new LogicalPosition(window.innerWidth / 2 - 1, window.innerHeight / 2 - 1),
                );
            }
            tbhCounterRef.current += 1;
          } else {
            void errorTone.tryPlay();
          }
        }
      }
    },
    undefined,
    true,
  );

  useEventListener(
    "mouseup",
    (event) => {
      if (event.buttons === 0 && event.button === TBH) {
        event.stopPropagation();
        tbhCounterRef.current = 0;
      }
    },
    undefined,
    { capture: true },
  );

  const CpdlcAdvComponent = selectedAdvView ? cpdlcAdvViewMap[selectedAdvView?.option] : null;

  return (
    <>
      <InopX />
      <SituationDisplayContextProvider sdRef={sdRef}>
        <CCommonContextProvider>
          <ButtonContextProvider>
            <BrightContextProvider>
              <DragContextProvider>
                <TearOffContextProvider>
                  <DrawContextProvider>
                    <CursorContextProvider>
                      <WeatherTearOffContextProvider>
                        <InputContextProvider>
                          <LockedUiBorder />
                          <SituationDisplay ref={sdRef} />
                          <MessageComposeArea />
                          <MessageResponseArea />
                          {CpdlcAdvComponent && <CpdlcAdvComponent key={selectedAdvView?.name} />}
                          <Time />
                          {asel && <AselComponent key={`ASEL_${asel.fpId}`} asel={asel} fpId={asel.fpId} />}
                          {unsafeEntries(buttonViewMap).map(
                            ([buttonId, Component]) =>
                              toggleButtonState[buttonId] && <Component key={buttonId} />,
                          )}
                          {unsafeEntries(cpdlcViewMap).map(
                            ([buttonId, Component]) =>
                              toggleButtonState[buttonId] && <Component key={buttonId} />,
                          )}
                          {eligibilityPrompt && (
                            <EligibilityOverridePrompt
                              key={eligibilityPrompt.callsign + eligibilityPrompt.title}
                              prompt={eligibilityPrompt}
                            />
                          )}
                          <VoiceRecordingPromptWrapper />
                          {selectedHeldToc && (
                            <CpdlcTocHeldPrompt key={`HELD_TOC_${selectedHeldToc}`} fpId={selectedHeldToc} />
                          )}
                          <container label="ButtonContainer" sortableChildren zIndex={layerZIndexMap.buttons}>
                            {unsafeEntries(tearOffPositionMap).map(
                              ([buttonId, pos]) =>
                                !isPriorityButton(buttonId) &&
                                pos && <EramTearOffButton key={buttonId} buttonId={buttonId} />,
                            )}
                            {Object.entries(macroButtonMap).map(
                              ([label, { position }]) =>
                                position && (
                                  <EramTearOffButton buttonId="MACRO_BUTTON" macroLabel={label} key={label} />
                                ),
                            )}
                          </container>
                          {priorityButtonList.map(
                            (buttonId) =>
                              tearOffPositionMap[buttonId] && (
                                <EramTearOffButton key={buttonId} buttonId={buttonId} />
                              ),
                          )}
                          <WeatherTearOffItems />
                        </InputContextProvider>
                      </WeatherTearOffContextProvider>
                    </CursorContextProvider>
                  </DrawContextProvider>
                </TearOffContextProvider>
              </DragContextProvider>
            </BrightContextProvider>
          </ButtonContextProvider>
        </CCommonContextProvider>
      </SituationDisplayContextProvider>
    </>
  );
};
