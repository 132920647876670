import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import type { DroneCommandData } from "@poscon/shared-types/eram";
import {
  colorNameMap,
  eramFontDimensionMap,
  eramFontNameMap,
  EramPrompt,
  getBitmapTextStyles,
  setVoiceRecordingPromptData,
  useCenterCursor,
  ViewItem,
  voiceRecordingPromptDataSelector,
} from "@poscon/shared-frontend";
import type { Container as PixiContainer } from "pixi.js";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import { chunkString } from "@poscon/shared-types";
import { useDragging } from "~/hooks/useDragging";
import { viewPositionSelector } from "~/redux/slices/eramStateSlice";
import { encode } from "@msgpack/msgpack";
import { layerZIndexMap } from "~/layerZIndexMap";

const view = "VOICE_RECORDING_PROMPT" as const;

const fontFamily = eramFontNameMap[2];
const { width: fontWidth, height: fontHeight } = eramFontDimensionMap[fontFamily];
const tint = colorNameMap.lightGrey;

function webTransportFactory() {
  if (!window.isSecureContext) {
    return null;
  }
  return new WebTransport("https://core.poscon.com/?access_token=NOT_USED_YET", {
    requireUnreliable: true,
    congestionControl: "low-latency",
  });
}

type VoiceRecordingPromptProps = {
  droneCommandData: DroneCommandData;
  wt: WebTransport;
  disableRecording: () => void;
  cancelPromptShown: boolean;
  setCancelPromptShown: (value: boolean) => void;
};

let recorder: MediaRecorder | null = null;

if (window.isSecureContext) {
  recorder = await navigator.mediaDevices
    .getUserMedia({
      audio: true,
    })
    .then((stream) => new MediaRecorder(stream));
}

export const VoiceRecordingPromptWrapper = () => {
  const voiceRecordingPromptData = useRootSelector(voiceRecordingPromptDataSelector);
  const [wt, setWt] = useState<WebTransport | null>(() => webTransportFactory());
  const [cancelPromptShown, setCancelPromptShown] = useState(false);
  const [recordingEnabled, setRecordingEnabled] = useState(true);
  const recordingEnabledRef = useRef(recordingEnabled);
  // eslint-disable-next-line react-compiler/react-compiler
  recordingEnabledRef.current = recordingEnabled;

  useEffect(() => {
    if (!recordingEnabled) {
      wt?.close();
      setWt(null);
    }
  }, [recordingEnabled, wt]);

  useEffect(() => {
    wt?.closed.then((info) => {
      console.log("WebTransport disconnected", info);
      if (recordingEnabledRef.current) {
        const wt = webTransportFactory();
        setWt(wt);
      }
    });
  }, [wt]);

  return recordingEnabled && wt && voiceRecordingPromptData ? (
    <VoiceRecordingPrompt
      key={voiceRecordingPromptData.callsign}
      wt={wt}
      droneCommandData={voiceRecordingPromptData}
      disableRecording={() => setRecordingEnabled(false)}
      cancelPromptShown={cancelPromptShown}
      setCancelPromptShown={setCancelPromptShown}
    />
  ) : null;
};

const VoiceRecordingPrompt = ({
  droneCommandData,
  wt,
  disableRecording,
  cancelPromptShown,
  setCancelPromptShown,
}: VoiceRecordingPromptProps) => {
  const ref = useRef<PixiContainer | null>(null);
  const localRef = useRef<PixiContainer | null>(null);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const { startDrag } = useDragging(ref, view);
  const chunksRef = useRef<Blob[]>([]);
  const [isRecording, setIsRecording] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const dispatch = useRootDispatch();
  const title = `${droneCommandData.callsign} RECORD VOICE SAMPLE`;
  const width = 40 * fontWidth + 8;
  const height = Math.floor(fontHeight * 8);

  const textLines = chunkString(droneCommandData.callsign + " " + droneCommandData.instructionText, 36, true);
  if (!droneCommandData.command && !droneCommandData.routeCommand) {
    textLines.unshift("WARNING: NO COMMAND GENERATED");
  }

  useCenterCursor(localRef, width, fontHeight + 4);

  useLayoutEffect(() => {
    const listener = (e: BlobEvent) => {
      chunksRef.current.push(e.data);
    };
    recorder?.addEventListener("dataavailable", listener);
    return () => {
      recorder?.removeEventListener("dataavailable", listener);
    };
  }, []);

  const send = async () => {
    // one stream for each recording and its metadata
    await wt.ready;
    const stream = await wt.createUnidirectionalStream();
    const writer = stream.getWriter();
    await writer.ready;
    try {
      await writer.write(encode("recording"));
      await writer.write(encode("receive"));
      // metadata comes first, either a MsgPack string of JSON or a MsgPack key-value Map directly
      await writer.write(encode(JSON.stringify(droneCommandData)));
      for await (const chunk of chunksRef.current) {
        // then loop until end of recording
        await writer.ready; // in-case we are too fast
        await writer.write(encode(new Uint8Array(await chunk.arrayBuffer())));
      }
      writer.write(encode(null));
    } catch {
      // ignore
    }
    await writer.close();
    console.log("Recording sent");
  };

  const startRecording = () => {
    chunksRef.current = [];
    recorder?.start();
    setIsRecording(true);
    console.log("Recording started");
  };

  const stopRecording = () => {
    recorder?.stop();
    setCanSend(true);
    setIsRecording(false);
    console.log("Recording stopped");
  };

  return recorder ? (
    <EramPrompt
      ref={ref}
      pos={pos}
      startDrag={startDrag}
      zIndex={layerZIndexMap.prompts + 1}
      title={title}
      height={height}
      width={width}
      showX
      closeMenu={() => dispatch(setVoiceRecordingPromptData(null))}
    >
      <bitmapText
        text={textLines.join("\n")}
        x={4}
        y={2}
        style={{ ...getBitmapTextStyles(fontFamily), fill: tint }}
      />
      <container y={Math.floor(height - fontHeight * 1.5)} ref={localRef}>
        <ViewItem
          x={fontWidth}
          width={Math.floor(fontWidth * 4.5)}
          eventMode={!isRecording && canSend ? "static" : "none"}
          text="SEND"
          fillColor={0x757575}
          tint={!isRecording && canSend ? tint : 0x888888}
          onmousedown={() => {
            dispatch(setVoiceRecordingPromptData(null));
            void send();
          }}
        />
        <ViewItem
          x={fontWidth * 6}
          width={Math.floor(fontWidth * 5.5)}
          eventMode={!isRecording ? "static" : "none"}
          text="START"
          fillColor={0x757575}
          tint={!isRecording ? tint : 0x888888}
          onmousedown={startRecording}
        />
        <ViewItem
          x={fontWidth * 12}
          width={Math.floor(fontWidth * 4.5)}
          eventMode={isRecording ? "static" : "none"}
          text="STOP"
          fillColor={0x757575}
          tint={isRecording ? tint : 0x888888}
          onmousedown={stopRecording}
        />
        <ViewItem
          x={width - Math.floor(fontWidth * 7.5)}
          width={Math.floor(fontWidth * 6.5)}
          text="CANCEL"
          fillColor={0x757575}
          borderColor={0x505050}
          tint={tint}
          onmousedown={async () => {
            if (!cancelPromptShown) {
              if (
                await window.confirm("Do you want disable voice training for the remainder of the session?")
              ) {
                disableRecording();
              }
            }
            setCancelPromptShown(true);
            dispatch(setVoiceRecordingPromptData(null));
          }}
        />
      </container>
    </EramPrompt>
  ) : null;
};
